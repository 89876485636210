import {
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const getArguments = (obj, keyList) => {
    let args = [];
    for (let key of keyList) {
        args.push(obj[key])
    }
    return args
}

function TableWithAction({ headers, rows, handleEdit }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="customized table">
                <TableHead>
                    <StyledTableRow>
                        {headers.map((header, i) =>
                            <StyledTableCell width={header.width}><span>{header.label}</span></StyledTableCell>
                        )}
                        <StyledTableCell align="center"><span>Actions</span></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.length ? rows.map((row) => (
                        <StyledTableRow
                            key={row.name}
                            onClick={() => {
                                handleEdit(row)
                            }}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                        >
                            {headers.map((header, i) =>
                                <StyledTableCell component="th" scope="row">
                                    {
                                        header.type == 'status' ?
                                            <div className='cell-label'>
                                                <CheckCircleIcon /> <span>{row[header.name] ? 'Active' : 'Inactive'}</span>
                                            </div> :
                                            header.type == 'custom' ?
                                                header.component(...getArguments(row, header.arguments)) :
                                                <span className='cell-text'>{row[header.name]}</span>
                                    }
                                </StyledTableCell>
                            )}
                            <StyledTableCell align="center">
                                <Button size="small" color="secondary" variant="outlined" startIcon={<EditIcon />}
                                    onClick={() => {
                                        handleEdit(row)
                                    }}
                                >
                                    Manage
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    )) : ''}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableWithAction;