import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    CardActions,
    Typography,
    CardMedia,
    Box,
    TextField,
    InputAdornment,
    FormLabel,
    FormControl,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Collapse,
    IconButton
} from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { ReactComponent as NoData } from 'assets/svgs/NoData.svg';
import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
import Loader from 'components/Loader';
import Carousel from 'components/Carousel';
import { useSelector } from 'react-redux';
import { updateUserDetail } from 'utils/auth';
import { GLOBAL_CURRENCY } from 'constants/appConstants';
import ImageUploader from 'components/Inputs/ImageUploader';
import { useSnackbar } from 'notistack';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PendingIcon from '@mui/icons-material/Pending';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OTP from 'components/Inputs/OTP';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Whastapp from 'components/Whatsapp/Whastapp';
import SimpleModal from '../Modal/DocsModal';
import { useModal } from 'utils/context';

let formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'ZAR',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
});

const Home = ({ breadcrumbs }) => {
    let shouldShowSimpleModal;
    const { open, handleClose, handleOpen } = useModal();
    const [loader, setLoader] = useState(true);
    const [salesSummary, setSalesSummary] = useState({});
    const [bestProduct, setBestProductList] = useState([]);
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const loggedInUser = useSelector(state => state.userDetails);
    const { user } = loggedInUser;
    const [logoUrl, setLogoUrl] = useState('');
    const [logo, setLogo] = useState([]);
    const [checklist, setCheckListStatus] = useState({});
    const [checkListComplete, setIfCheckListComplete] = useState(false);
    const [shippingAmount, setshippingAmount] = useState(null);
    // console.log("checklist",checklist)
    
    const getSalesReport = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.SALES_ANALYTICS)
        if (res === false) {
            setLoader(false);
            return
        }

        let { data, response } = res;

        if (data) {
            setSalesSummary(data)
        }
    }


    const getShippingAmount = async () => {
        try {
            setLoader(true);
            let res = await axiosInstance.get(
                APIRouteConstants.SHIPRAZOR.GET_SHIPPING_AMOUNT
            );

            if (res === false) {
                setLoader(false);
                return
            }

            let { data, response } = res;
            if (data && res.status) {
                setshippingAmount(data?.amount);
                sessionStorage.setItem("shippng_amount", JSON.stringify(data?.amount))
            } else {
                enqueueSnackbar(response?.data?.error, { variant: "error" });
            }
            setLoader(false);
        } catch (err) {
            console.log("getShippingAmount", err);
        }
    };


    const getBestSellingProducts = async () => {
        let res = await axiosInstance.get(APIRouteConstants.STORE.TOP_COLLECTION)
        let { data, response } = res;

        if (res === false) {
            setLoader(false);
            return
        }

        if (data) {
            setBestProductList(data)
        }
        setLoader(false)
    }

    console.log("user", user)
    const loadWebsiteDetail = async () => {
        if (!_.isEmpty(user)) {
            var webSetup = false
            // if (user.storeName && user.hostName && user?.logo !== null && user?.logo !== undefined) webSetup = true
            if (user.storeName && user.hostName) webSetup = true

            if (user.logo) {
                setLogo([user.logo])
                setLogoUrl(user.logo)
            }
            setCheckListStatus({
                webSetup: webSetup,
                // shiprazorSetup: user?.shiprazor_key_exist ? true : (user.own_shipping_selected && shippingAmount !== null) ? true : false,
                shiprazorSetup: user?.is_shiprazor_selected ? true : (user.own_shipping_selected && user?.shipping_amount !== null) ? true : false,
                warehouseSetup: user.warehouse_exist,
                categoryExist: user.is_category,
                productExist: user.is_product,
                paymentSetup: user.is_payment_selected
            })
        }
        setLoader(false)
    }
    // console.log("selected", user.is_shiprazor_selected, user?.own_shipping_selected)
    useEffect(() => {
        if (!_.isEmpty(user)) {
            if (user.isPublished) {
                getSalesReport()
                getBestSellingProducts()
            } else {
                let setup = true
                if (!user.storeName || !user.hostName) setup = false
                // else if (!user.shiprazor_key_exist) setup = false
                else if (!user.is_shiprazor_selected && !user.own_shipping_selected) setup = false
                else if (!user.warehouse_exist) setup = false
                else if (!user.is_category) setup = false
                else if (!user.is_product) setup = false
                else if (!user.is_product) setup = false
                else if (!user.is_payment_selected) setup = false
                setIfCheckListComplete(setup)
            }
            loadWebsiteDetail()
        }
        if (user?.own_shipping_selected) {
            getShippingAmount()
        }
    }, [user])

    useEffect(() => {
        handleNext();
    }, [])

    const getUser = async () => {
        let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)

        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (data && data.success) {
            const user = data.data;
            updateUserDetail(user)
        }
        setLoader(false);
    }

    const handlePublish = async () => {
        setLoader(true)
        let res = await axiosInstance.put(APIRouteConstants.WEBSITE.PUBLISH_WEBSITE, {
            "is_Published": true
        })

        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (!_.isEmpty(data)) {
            getUser();
        }
    }

    const handleStoreUpdate = async () => {
        let res = await axiosInstance.post(APIRouteConstants.WEBSITE.UPDATE_STORE)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data } = res;

        if (data) {
            getUser()
        }
    }

    const handleNext = async () => {
        setLoader(true)
        await getUser()
        setLoader(false)
    };


    shouldShowSimpleModal =  localStorage.getItem("ischecklistopened")


    console.log("shouldShowSimpleModal",open)
    return (
      <div className="home-wrapper">
        <Loader open={loader} />
        <div className="breadcrumb-wrapper">
          <CustomBreadcrumbs
            list={breadcrumbs}
            name={user?.isPublished ? "Home" : "Complete your store setup"}
          />
        </div>
        {/* <SimpleModal open={open} handleClose={handleClose}  /> */}
        <Grid
          container
          className="component-grid"
          spacing={3}
          sx={{ gridAutoRows: "1fr" }}
        >
          <Grid item xs={12} md={12}>
            <Card className="setup-card dashboard-card-wrapper">
              <CardContent className="stepper-wrapper">
                <CheckListItem
                  title={"Set up your website and storefront [Required]"}
                  isCollapsible={true}
                  isMandatory={true}
                  // isDisabled={checklist.webSetup}
                  description={
                    "Tell us a bit about your business so we can get you up and running. To help you get started we'll give you a free sub domain."
                  }
                  status={checklist.webSetup}
                >
                  {!loader ? (
                    <StoreDetail
                      user={user}
                      store={{
                        name: user?.storeName,
                        logo: logo,
                        logoUrl: logoUrl,
                        host: user?.hostName,
                      }}
                      loader={loader}
                      setLogo={setLogo}
                      status={checklist.webSetup}
                      setLoader={setLoader}
                      handleUpdate={handleStoreUpdate}
                    />
                  ) : (
                    ""
                  )}
                </CheckListItem>
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  redirectTo={dashboardRouteConstants.ABOUT}
                  title={"Review and Update about your business [Optional]"}
                  description={
                    "Congrats! You have successfully setup your Store and domain. Please provide more details about your business to go ahead."
                  }
                  status={true}
                  isOptional={true}
                  isDisabled={!checklist.webSetup}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  redirectTo={dashboardRouteConstants.SHIPPING}
                  isMandatory={true}
                  isDisabled={!checklist.webSetup}
                  title={"Please choose your shipping method:"}
                  description={
                    "Would you like to use Shiprazor (recommended) or arrange your own shipping?"
                  }
                  status={checklist.shiprazorSetup}
                >
                  {/* <ShipRazorSetup
                                    refreshUser={getUser} 
                                    setLoader={setLoader} /> */}
                </CheckListItem>
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  redirectTo={dashboardRouteConstants.DELIVERY}
                  title={"Choose how to deliver your goods"}
                  status={checklist.warehouseSetup}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  title={"Add categories and products"}
                  redirectTo={
                    !checklist.categoryExist
                      ? dashboardRouteConstants.CATEGORIES
                      : dashboardRouteConstants.PRODUCTS
                  }
                  status={checklist.categoryExist && checklist.productExist}
                />
                <Divider className="divider" />
                <CheckListItem
                  isCollapsible={false}
                  isDisabled={!checklist.webSetup}
                  title={"Set up payment options"}
                  redirectTo={dashboardRouteConstants.PAYMENTS}
                  status={checklist.paymentSetup}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className="overlay-wrapper dashboard-card-wrapper">
              <CardContent>
                <div className="header-wrapper">
                  <span className="header-text">Your Dashboard</span>
                </div>
                <Divider />
                <div className="header-wrapper">
                  <span className="header-text">Total Sales</span>
                  <br />
                  <span className="header-sec-text">
                    Overall customer purchases.
                  </span>
                </div>
                <div className="content-wrapper content-flex content-flex-right">
                  <span className="content-text-right figure-text">
                    {/* {salesSummary['total_sale'] ? GLOBAL_CURRENCY.symbol + salesSummary['total_sale'].toFixed(2) : GLOBAL_CURRENCY.symbol + '0.00'} */}
                    {/* {new Intl.NumberFormat({style:"currency", currency:"ZAR", minimumFractionDigits: 2, currencyDisplay: 'narrowSymbol'}).format(salesSummary['total_sale'] || 0)} */}
                    {formatCurrency.format(salesSummary["total_sale"] || 0)}
                  </span>
                </div>
                <Divider />
                <div className="header-wrapper">
                  <span className="header-text">Today Sales</span>
                  <br />
                  <span className="header-sec-text">
                    Total sale by customer purchases.
                  </span>
                </div>
                <div className="content-wrapper content-flex content-flex-right">
                  <span className="content-text-right figure-text">
                    {/* {salesSummary['today_sale'] ? GLOBAL_CURRENCY.symbol + salesSummary['today_sale'].toFixed(2) : GLOBAL_CURRENCY.symbol + '0.00'} */}
                    {formatCurrency.format(salesSummary["today_sale"] || 0)}
                  </span>
                </div>
              </CardContent>
              {user?.isPublished ? (
                ""
              ) : (
                <div className="overlay-card">
                  <NoData className="statement-svg" />
                  <span className="statement">
                    This content will get available
                    <br /> after publishing website
                  </span>
                </div>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className="overlay-wrapper dashboard-card-wrapper">
              {user?.isPublished ? (
                <CardContent>
                  <div className="header-wrapper">
                    <span className="header-text">View your website</span>
                  </div>
                  <Divider />
                  <div className="header-wrapper">
                    <span className="header-sec-text">
                      Click here to view your website
                    </span>
                  </div>
                  <div className="content-wrapper content-flex content-flex-right">
                    <Button
                      onClick={() => {
                        window
                          .open(
                            `https://${user.websiteUrl}.webbieshop.com`,
                            "_blank"
                          )
                          .focus();
                      }}
                      variant="contained"
                    >
                      View your Website
                    </Button>
                  </div>
                </CardContent>
              ) : (
                <CardContent>
                  <div className="header-wrapper">
                    <span className="header-text">Publish your website</span>
                  </div>
                  <Divider />
                  <div className="header-wrapper">
                    <span className="header-sec-text">
                      Click here to publish your website
                    </span>
                  </div>
                  <div className="content-wrapper content-flex content-flex-right">
                    <Button onClick={handlePublish} variant="contained">
                      Publish your Website
                    </Button>
                  </div>
                </CardContent>
              )}
              {!checkListComplete && !user?.isPublished ? (
                <div className="overlay-card">
                  <NoData
                    className="statement-svg"
                    style={{ height: "3rem" }}
                  />
                  <span className="statement">
                    This content will get available
                    <br /> after publishing website
                  </span>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid container className="component-grid">
          <Grid item xs={12}>
            <Card className="overlay-wrapper dashboard-card-wrapper">
              <CardContent>
                <div className="header-wrapper">
                  <span className="header-text">Best Selling Products</span>
                </div>
                <Divider />

                {bestProduct.length > 0 ? (
                  <div className="content-wrapper content-flex content-flex-center">
                    <Carousel>
                      {bestProduct.map((product) => (
                        <DetailCard product={product} navigate={navigate} />
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <div className="content-wrapper content-flex content-flex-center">
                    <NoData />
                    <span className="content-text">No results found</span>
                  </div>
                )}
              </CardContent>
              {user?.isPublished ? (
                ""
              ) : (
                <div className="overlay-card">
                  <NoData className="statement-svg" />
                  <span className="statement">
                    This content will get available
                    <br /> after publishing website
                  </span>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
        {/* <Whastapp mobile={user?.mobile_number} /> */}
      </div>
    );
}

const CheckListItem = (props) => {
    const { title, description, status, isCollapsible, isMandatory, redirectTo, isDisabled, isOptional } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(!isDisabled && !status)
    }, [isDisabled, status])

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box className="checklist-wrapper">
            <span className='title'></span>
            <ListItemButton
                disabled={isDisabled}
                onClick={() => {
                    if (isCollapsible) handleClick()
                    else navigate(redirectTo)
                }}>
                <ListItemIcon>
                    {status ?
                        <CheckIcon color="success" /> :
                        isOptional ?
                            <PendingIcon style={{ color: '#ffc400' }} /> :
                            isMandatory ?
                                <PriorityHighIcon color='error' /> :
                                <AnnouncementIcon color='warning' />}
                </ListItemIcon>
                {/* <ListItemText primary={title} /> */}
                <ListItemText>
                    <span className='title'>
                        {title}
                    </span><br />
                    <span className='description'>
                        {description}
                    </span>
                </ListItemText>
                {isCollapsible ?
                    open ? <ExpandLess /> : <ExpandMore /> :
                    <IconButton
                        aria-label="delete"
                        className='icon-btn-directional'
                        size="small">
                        <ArrowForwardIosIcon />
                    </IconButton>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {props.children}
            </Collapse>
        </Box>
    )
}

const DetailCard = ({ product, navigate }) => (
    <Card className="slider-card content-card">
        <CardMedia
            sx={{ height: '17rem' }}
            image={product?.product_images[0]?.image}
            title={product?.name}
        />
        <CardContent align="left">
            {/* <Typography gutterBottom component="span">
                {product.name ? _.truncate(product.name, { length: 30 }) : ''}
            </Typography> */}
            <CardContent align="left">
                <Typography
                    sx={{
                        display: { xs: "none", sm: "none", md: "flex" },
                        minHeight: "10vh",
                        fontSize: 18,
                        alignItems: "baseline"

                    }}
                    gutterBottom component="span">
                    {_.truncate(_.trim(product.name), { length: 30 })}
                </Typography>
                <Typography
                    sx={{
                        display: { xs: "flex", sm: "flex", md: "none" },
                    }}
                    gutterBottom component="span">
                    {_.truncate(_.trim(product.name), { length: 40 })}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {formatCurrency.format(product?.price || 0)}
                </Typography>
            </CardContent>
        </CardContent>
        <CardActions align="center">
            <Button
                color="success"
                size="small"
                onClick={() => {
                    navigate(dashboardRouteConstants.PRODUCTS, { state: { productId: product.id } })
                }}
                variant="outlined">View</Button>
        </CardActions>
    </Card>
)

const StoreDetail = ({ setLoader, store, setLogo, handleUpdate, status, loader }) => {
    const { formState: { errors }, handleSubmit, control, getValues } = useForm();
    const { enqueueSnackbar } = useSnackbar();

    const updateStoreDetails = async (formData) => {
        const { logo, logoUrl } = store;
        // if (!logo.length) return;

        setLoader(true);

        let res = await axiosInstance.post(APIRouteConstants.WEBSITE.ADD_WEB_DETAILS, formData)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if(data && data.success){   
            console.log("response12",data.message)
            enqueueSnackbar( data.message, { variant: 'success' });
            handleUpdate()
        }

        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
                // setLoader(false);
                // return;
            }
        }

        let logoFormData = new FormData();
        if(logo[0] ===undefined) {
            setLoader(false);
            return
         }        
       if (logo[0] != logoUrl) {
            logoFormData.append('logo', logo[0], logo[0].fileName)

            const { logoData, logoResponse } = await axiosInstance({
                method: 'post',
                url: APIRouteConstants.WEBSITE.WEB_LOGO_BANNER,
                data: logoFormData
            })

            if (logoResponse
                && logoResponse.status === 400
                && logoResponse.data) {
                for (let i in logoResponse.data) {
                    enqueueSnackbar(_.capitalize(i) + ': ' + logoResponse.data[i], { variant: 'error' });
                    // setLoader(false);
                    // return;
                }
            }

            if (!_.isEmpty(logoData)) {
                enqueueSnackbar('Details updated successfully', { variant: 'success' });
            }
        }
        handleUpdate()
    }

    console.log("response",store)
    return (
      <form
        className="step-form-container"
        onSubmit={handleSubmit(updateStoreDetails)}
      >
        <Grid
          container
          className="form-grid-container dashboard-form-container"
          spacing={4}
          wrap="wrap"
        >
          <Grid item md={12} sm={12}>
            <div className="form-wrapper">
              <FormLabel className="form-label">
                <span className="label-text">
                  Store name
                  <span className="required">*</span>
                </span>
                <br />
                <span className="label-sec-text">
                  This is the name we will display for your online store
                </span>
              </FormLabel>
              <FormControl fullWidth className="select-wrapper">
                <Controller
                  control={control}
                  name="store_name"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 16,
                      message: "Store name must be 16 characters or fewer to maintain proper website display.",
                    },
                  }}
                  defaultValue={store.name}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      margin="dense"
                      id="name"
                      placeholder="Store Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={onChange}
                      value={value}
                      disabled={status}
                      InputLabelProps={{ shrink: false }}
                      error={!!errors.store_name}
                      helperText={errors.store_name?.message}
                    />
                  )}
                />
                <span className="error-text">
                  {errors.store_name?.type === "required" &&
                    "Store name is required"}
                </span>
              </FormControl>
            </div>
            <div className="form-wrapper">
              <FormLabel className="form-label">
                <span className="label-text">
                  Store website address (URL)
                  <span className="required">*</span>
                </span>
                <br />
                <span className="label-sec-text">
                  You can change your free domain name later.
                </span>
              </FormLabel>
              <FormControl fullWidth className="select-wrapper">
                <Controller
                  control={control}
                  name="host_name"
                  rules={{ required: true }}
                  defaultValue={store.host}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      margin="dense"
                      id="name"
                      placeholder="Host Name"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={onChange}
                      disabled={status}
                      value={value}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            .webbieshop.com
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <span className="error-text">
                  {errors.host_name?.type === "required" &&
                    "Host name is required"}
                </span>
              </FormControl>
            </div>
          </Grid>
          <Grid item md={12} sm={12}>
            <div className="form-wrapper">
              <FormLabel className="form-label">
                <span className="label-text">
                  Upload your logo (Optional)
                  {/* <span className='required'>*</span> */}
                </span>
                <br />
                <span className="label-sec-text">
                  This is the logo we will display for your online store.
                </span>
              </FormLabel>
              <FormControl fullWidth className="select-wrapper">
               
                  <ImageUploader
                    imageList={store.logo}
                    setImageList={setLogo}
                    id="logo"
                    name="logo"
                    isDisabled={status}
                  />
              
                {/* <span className="error-text">
                                {!store.logo.length && (
                                    'Logo is required'
                                )}
                            </span> */}
              </FormControl>
            </div>
          </Grid>
          {!status ? (
            <Grid item md={12} sm={12}>
              <Button type="submit" variant="contained">
                Save and Continue {">"}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </form>
    );
}

const ShipRazorSetup = ({ setLoader, refreshUser }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [showVerify, setOpenVerify] = useState(false);
    const [otp, setOTP] = useState('');

    const verifyShipRazorAcc = async () => {
        setLoader(true)
        let res = await axiosInstance.post(APIRouteConstants.SHIPRAZOR.SR_ACCOUNT)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        console.log('Ship razor ', data, response)

        if (response
            && _.get(response, 'data')
            && _.get(response.data, 'shiprazor_response')
            && response.data.shiprazor_response.status == 400) {
            enqueueSnackbar(response.data.error, { variant: 'error' });
        }

        // setOpenVerify(true);

        if (data
            && _.get(data, 'shiprazor_response')) {
            enqueueSnackbar('OTP sent to your registerd phone number', { variant: 'success' });
            setOpenVerify(true);
            // details.hostName = data['host_name']
            // setHostName(data['host_name'])
        }
        setLoader(false)
    }

    const verifyOTP = async () => {
        setLoader(true)
        let res = await axiosInstance.post(APIRouteConstants.SHIPRAZOR.VERIFY_OTP, {
            otp: otp
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && _.get(response, 'data')
            && _.get(response.data, 'shiprazor_response')
            && response.data.shiprazor_response.status == 400) {
            enqueueSnackbar(response.data.error, { variant: 'error' });
        }

        if (data
            && _.get(data, 'success')) {
            setOpenVerify(true);
            refreshUser()
        }
        setLoader(false)
    }

    return (
        <Grid container className='step-form-container form-grid-container dashboard-form-container' spacing={0} wrap='wrap'>
            {!showVerify ?
                <Grid item md={12} sm={12} className='action-grid'>
                    <img className="logo-image" src={require('../../../assets/images/shiprazor.jpeg')} alt="" />
                    {/* <span className=''>Don’t have an account. Create one now for FREE </span> */}
                    <Button href='https://www.shiprazor.com/signup' target='_blank' variant='contained' color='secondary' style={{ marginRight: '1rem' }}>
                        Create Account Now
                    </Button>
                    <Button onClick={verifyShipRazorAcc} variant='contained' color='success'>
                        Have an account? Link Now.
                    </Button>
                </Grid> :
                <Grid item md={12} sm={12}>
                    <div className='form-wrapper'>
                        <FormLabel className='form-label'>
                            <span className='label-text'>
                                Enter verification code send to your registered phone number
                            </span>
                        </FormLabel>
                        <FormControl fullWidth className='select-wrapper'>
                            <OTP
                                separator={<span>-</span>}
                                value={otp}
                                onChange={setOTP}
                                length={6} />
                            <span className="error-text">
                                {/* {errors.store_name?.type === "required" && (
                                'Store name is required'
                            )} */}
                            </span>
                        </FormControl>
                        <div style={{ marginTop: '1rem' }} className='action-wrapper'>
                            <Button
                                sx={{ width: 'fit-content', mr: 1 }}
                                variant="contained"
                                onClick={verifyOTP}
                                color="secondary">Verify</Button>
                            <Button
                                sx={{ width: 'fit-content' }}
                                // variant="outlined" 
                                onClick={() => {
                                    setOpenVerify(false)
                                }}
                                color="secondary">Cancel</Button>
                        </div>
                    </div>
                </Grid>}
        </Grid>
    )
}

export default Home;