// import { useState, useEffect } from 'react';

// import {
//     Card,
//     Divider,
//     Grid,
//     CardContent,
//     CardActions,
//     Switch,
//     FormLabel,
//     FormControl,
//     TextField,
//     InputAdornment,
//     Collapse,
//     Button
// } from '@mui/material';
// import _ from 'lodash';

// import axiosInstance from "configs/axiosConfig";
// import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';
// import Loader from 'components/Loader';
// import CustomBreadcrumbs from "components/Breadcrumbs";
// import FormDialog from 'components/Dialog/FormDialog';
// import { enqueueSnackbar } from 'notistack';
// import { updateUserDetail } from 'utils/auth';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// const Payments = ({ breadcrumbs }) => {
//     const [loader, setLoader] = useState(false);
//     const [allPaymentModes, setPaymentModes] = useState([]);
//     const [peachCredential, setPeachCredential] = useState();
//     const [isPaymentDialogOpen, setPaymentDialogOpen] = useState(false);
//     const [paymentFormData, setFormData] = useState({
//         entityId: '',
//         userName: '',
//         password: ''
//     });
//     const loggedInUser = useSelector(state => state.userDetails);
//     const { user } = loggedInUser;
//     const navigate = useNavigate();

//     const getUser = async () => {
//         let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)
//         if (res === false) {
//             setLoader(false);
//             return
//         }
//         let { data, response } = res;

//         if (data && data.success) {
//             const user = data.data;
//             updateUserDetail(user)
//         }
//         setLoader(false);
//     }

//     const getPaymentModes = async () => {
//         setLoader(true)
//         let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_PAYMENT_MODES)
//         if (res === false) {
//             setLoader(false);
//             return
//         }
//         let { data, response } = res;

//         if (data) {
//             setPaymentModes(data)
//         }
//         setLoader(false)
//     }

//     useEffect(() => {
//         getPaymentModes()
//         getPeachPaymentCredentials()
//     }, [])

//     const updatePaymentMode = async (paymentModeId, status) => {
//         setLoader(true)
//         let res = await axiosInstance.put(APIRouteConstants.STORE.UPDATE_PAYMENT_MODE(paymentModeId), {
//             status: status
//         })
//         if (res === false) {
//             setLoader(false);
//             return
//         }
//         let { data } = res;

//         if (data) {
//             getPaymentModes();
//             getUser()
//         }
//         setLoader(false)
//     }

//     const getPeachPaymentCredentials = async () => {
//         setLoader(true);
//         let res = await axiosInstance.get(APIRouteConstants.STORE.GET_PEACH_CREDENTIAL)
//         if (res === false) {
//             setLoader(false);
//             return
//         }
//         let { data } = res;

//         if (data) {
//             setPeachCredential(data[0]);
//             // showModeDetail(true)
//         }
//         setLoader(false)
//     }

//     const addPeachPaymentCredentials = async () => {
//         if (
//             !paymentFormData.entityId
//         ) {
//             enqueueSnackbar('Please provide all mandatory details', { variant: 'error' });
//             return;
//         }
//         setLoader(true);
//         let res = await axiosInstance.post(APIRouteConstants.STORE.SET_PEACH_CREDENTIAL, {
//             username: paymentFormData.userName,
//             entity_id: paymentFormData.entityId,
//             password: paymentFormData.password
//         })
//         if (res === false) {
//             setLoader(false);
//             return
//         }
//         let { data, response } = res;

//         if (response
//             && response.status === 400
//             && response.data) {
//             if (response.data.error) {
//                 enqueueSnackbar(response.data.error.message, { variant: 'error' });
//                 setLoader(false)
//             } else
//                 for (let i in response.data) {
//                     enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i][0], { variant: 'error' });
//                     setLoader(false)
//                 }
//         }

//         if (!_.isEmpty(data)) {
//             setPaymentDialogOpen(false);
//             getPeachPaymentCredentials();
//             getPaymentModes()
//         }
//         // showModeDetail(true)
//     }

//     const [modeDetail, showModeDetail] = useState(true);

//     return (
//         <div className="website-analytics-wrapper">
//             <Loader open={loader} />
//             <div className='breadcrumb-wrapper'>
//                 <CustomBreadcrumbs list={breadcrumbs} name={"Payments"} />
//             </div>
//             <Grid container className='component-grid'>
//                 <Grid item xs={12}>
//                     <Card className='list-wrapper dashboard-card-wrapper'>
//                         <CardContent>
//                             <div className='header-wrapper'>
//                                 <span className='header-text'>Payment Methods</span>
//                             </div>
//                             <Divider />
//                             <div className='content-wrapper content-flex'>
//                                 <span className='content-text'>
//                                     To start accepting payments on your online store, you need to set up the payment method(s) you prefer to use for your business. It is important to offer a variety of methods to pay including Credit card, Mobile Money etc. The more ways you enable your customers to pay, the higher chance you have of making sales. Always make it easier for your customers to pay in the way that suits them.
//                                 </span>
//                             </div>
//                             <div className='content-wrapper content-flex'>
//                                 <Grid container className='filter-wrapper'>
//                                     {allPaymentModes.length > 0 ? allPaymentModes.map((mode) => (
//                                         <Grid item sm={12} className='payment-card'>
//                                             <div className='payment-header'>

//                                                 <div className='detail-sec-wrapper'>
//                                                     {_.includes(_.toLower(_.replace(mode['payment_method_name'], ' ', '')), 'peach') ?
//                                                         <div>
//                                                             <img className="logo-image" src={require('../../../../assets/images/peach.png')} alt="" />
//                                                         </div>
//                                                         : ''}
//                                                     <div>
//                                                         <span className='detail-sec-text'>{mode['payment_method_name']}<br /></span>
//                                                         {/* {_.includes(_.toLower(_.replace(mode['payment_method_name'], ' ', '')), 'peach') ?
//                                                 <span className='detail-sec-text'>
//                                                     <a className="label-text"
//                                                         onClick={() => {
//                                                             if (!modeDetail)
//                                                                 showModeDetail(true)
//                                                             else {
//                                                                 showModeDetail(false)
//                                                             }
//                                                         }}>

//                                                         {modeDetail ? 'Read Less' : 'Read More'}
//                                                     </a>
//                                                 </span>
//                                             : ''} */}
//                                                     </div>
//                                                 </div>
//                                                 <Switch
//                                                     checked={mode['status']}
//                                                     color="success"
//                                                     disabled={
//                                                         _.includes(_.toLower(_.replace(mode['payment_method_name'], ' ', '')), 'peach') ?
//                                                             !_.isEmpty(peachCredential) ? false : true
//                                                             : false}
//                                                     onChange={() => {
//                                                         updatePaymentMode(mode['id'], !mode['status'])
//                                                     }}
//                                                 />
//                                             </div>
//                                             {_.includes(_.toLower(_.replace(mode['payment_method_name'], ' ', '')), 'peach') ?
//                                                 <Collapse in={modeDetail} timeout="auto" unmountOnExit>
//                                                     <Divider className="divider" />
//                                                     {!_.isEmpty(peachCredential) ?
//                                                         <div className="payment-detail">
//                                                             <span>Entity Id : {_.get(peachCredential, 'entity_id')}</span><br />
//                                                             <span>Secret Token : {_.get(peachCredential, 'username')}</span><br />
//                                                             {/* <span>Password : {_.get(peachCredential, 'password')}</span><br /> */}
//                                                             <span>Password : xxxxxxx</span><br />
//                                                             {/* <span>Access Key : {_.get(peachCredential, 'access_key')}</span><br /> */}
//                                                         </div> :
//                                                         <div className="payment-detail">
//                                                             <Button href='https://www.peachpayments.com/resources/get-started'
//                                                                 target='_blank' variant='contained' color='secondary' style={{ marginRight: '1rem' }}>
//                                                                 Create Account Now
//                                                             </Button>
//                                                             <Button
//                                                                 onClick={() => {
//                                                                     setPaymentDialogOpen(true);
//                                                                 }}
//                                                                 variant='contained'
//                                                                 color='success'>
//                                                                 Have an account? Link Now.
//                                                             </Button>
//                                                             {/* <br /><br />
//                                                         <span><b>Instructions:</b></span><br />
//                                                         <span>1. Go to{" "}
//                                                             <a target="_blank" href="https://www.peachpayments.com/resources/get-started">
//                                                                 Peach Payment</a> {" "}to setup credentials</span><br /> */}
//                                                         </div>}
//                                                 </Collapse> : ''}
//                                         </Grid>
//                                     )) : ''}
//                                 </Grid>
//                             </div>
//                         </CardContent>
//                         {!user?.isPublished ?
//                             <CardActions className='footer-wrapper'>
//                                 <div className="action-wrapper">
//                                     <Button
//                                         variant="outlined"
//                                         color="secondary"
//                                         style={{ backgroundColor: 'white' }}
//                                         onClick={() => {
//                                             navigate(dashboardRouteConstants.HOME)
//                                         }}>
//                                         Next {'>'}
//                                     </Button>
//                                 </div>
//                             </CardActions>
//                             : ''}
//                     </Card>
//                 </Grid>
//                 <FormDialog
//                     setOpen={() => setPaymentDialogOpen(!isPaymentDialogOpen)}
//                     viewSave={true}
//                     viewCancel={true}
//                     open={isPaymentDialogOpen}
//                     className="credential-dialog"
//                     title={"Set Peach Payments Checkout Credentials"}
//                     handleSave={() => addPeachPaymentCredentials()}
//                 >
//                     <div className='credential-wrapper'>
//                         <Grid container>
//                             <Grid xs={12} className='form-wrapper'>
//                                 <FormLabel className='form-label'>
//                                     <span className='label-text'>
//                                         Entity Id
//                                         <span className='required'>*</span>
//                                     </span>
//                                 </FormLabel>
//                                 <FormControl className='form-control'>
//                                     <TextField
//                                         margin="dense"
//                                         id="name"
//                                         placeholder="Entity Id"
//                                         type="text"
//                                         fullWidth
//                                         variant="outlined"
//                                         size="small"
//                                         onChange={e => {
//                                             setFormData({
//                                                 ...paymentFormData,
//                                                 entityId: e.target.value
//                                             })
//                                         }}
//                                         InputLabelProps={{ shrink: false }}
//                                     />
//                                 </FormControl>
//                             </Grid>
//                             <Grid xs={12} className='form-wrapper'>
//                                 <FormLabel className='form-label'>
//                                     <span className='label-text'>
//                                         Secret Token
//                                         {/* <span className='required'>*</span> */}
//                                     </span>
//                                 </FormLabel>
//                                 <FormControl className='form-control'>
//                                     <TextField
//                                         margin="dense"
//                                         id="name"
//                                         placeholder="Username"
//                                         type="text"
//                                         fullWidth
//                                         variant="outlined"
//                                         size="small"
//                                         onChange={e => {
//                                             setFormData({
//                                                 ...paymentFormData,
//                                                 userName: e.target.value
//                                             })
//                                         }}
//                                         InputLabelProps={{ shrink: false }}
//                                     />
//                                 </FormControl>
//                             </Grid>
//                             <Grid xs={12} className='form-wrapper'>
//                                 <FormLabel className='form-label'>
//                                     <span className='label-text'>
//                                         Password
//                                         {/* <span className='required'>*</span> */}
//                                     </span>
//                                 </FormLabel>
//                                 <FormControl className='form-control'>
//                                     <TextField
//                                         margin="dense"
//                                         id="name"
//                                         placeholder="xxxxxxxxxxxxxx"
//                                         type="password"
//                                         fullWidth
//                                         variant="outlined"
//                                         size="small"
//                                         onChange={e => {
//                                             setFormData({
//                                                 ...paymentFormData,
//                                                 password: e.target.value
//                                             })
//                                         }}
//                                         InputLabelProps={{ shrink: false }}
//                                     />
//                                 </FormControl>
//                             </Grid>
//                         </Grid>
//                     </div>
//                 </FormDialog>
//             </Grid>
//         </div>
//     )
// }

// export default Payments

//with sticit pay ui

import { useState, useEffect } from "react";

import {
  Card,
  Divider,
  Grid,
  CardContent,
  CardActions,
  Switch,
  FormLabel,
  FormControl,
  TextField,
  InputAdornment,
  Collapse,
  Button,
} from "@mui/material";
import _ from "lodash";

import axiosInstance from "configs/axiosConfig";
import {
  APIRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import Loader from "components/Loader";
import CustomBreadcrumbs from "components/Breadcrumbs";
import FormDialog from "components/Dialog/FormDialog";
import { enqueueSnackbar } from "notistack";
import { updateUserDetail } from "utils/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Payments = ({ breadcrumbs }) => {
  const [loader, setLoader] = useState(false);
  const [allPaymentModes, setPaymentModes] = useState([]);
  const [peachCredential, setPeachCredential] = useState();
  const [sticittCredential, setSticittCredential] = useState();
  const [isPaymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [isPaymentDialogOpenSticitt, setPaymentDialogOpenSticitt] = useState(false);
  const [paymentFormData, setFormData] = useState({
    entityId: "",
    userName: "",
    password: "",
  });
  const [paymentFormDataSticitt, setFormDataSticitt] = useState({
    merchantId: "",
  });
  const loggedInUser = useSelector((state) => state.userDetails);
  const { user } = loggedInUser;
  const navigate = useNavigate();

  const getUser = async () => {
    let res = await axiosInstance.get(APIRouteConstants.AUTH.ME);
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (data && data.success) {
      const user = data.data;
      updateUserDetail(user);
    }
    setLoader(false);
  };

  const getPaymentModes = async () => {
    setLoader(true);
    let res = await axiosInstance.get(
      APIRouteConstants.STORE.ALL_PAYMENT_MODES
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (data) {
      setPaymentModes(data);
    }
    setLoader(false);
  };

  useEffect(() => {
    getPaymentModes();
    getPeachPaymentCredentials();
    getSticittPaymentCredentials()
  }, []);

  const updatePaymentMode = async (paymentModeId, status) => {
    setLoader(true);
    let res = await axiosInstance.put(
      APIRouteConstants.STORE.UPDATE_PAYMENT_MODE(paymentModeId),
      {
        status: status,
      }
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data } = res;

    if (data) {
      getPaymentModes();
      getUser();
    }
    setLoader(false);
  };

  const getPeachPaymentCredentials = async () => {
    setLoader(true);
    let res = await axiosInstance.get(
      APIRouteConstants.STORE.GET_PEACH_CREDENTIAL
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data } = res;

    if (data) {
      setPeachCredential(data[0]);
      // showModeDetail(true)
    }
    setLoader(false);
  };
  const getSticittPaymentCredentials = async () => {
    setLoader(true);
    let res = await axiosInstance.get(
      APIRouteConstants.STORE.GET_STICITT_CREDENTIAL
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data } = res;

    if (data) {
      setSticittCredential(data.data);
      // showModeDetail(true)
    }
    setLoader(false);
  };

  const addPeachPaymentCredentials = async () => {
    if (!paymentFormData.entityId) {
      enqueueSnackbar("Please provide all mandatory details", {
        variant: "error",
      });
      return;
    }
    setLoader(true);
    let res = await axiosInstance.post(
      APIRouteConstants.STORE.SET_PEACH_CREDENTIAL,
      {
        username: paymentFormData.userName,
        entity_id: paymentFormData.entityId,
        password: paymentFormData.password,
      }
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      if (response.data.error) {
        enqueueSnackbar(response.data.error.message, { variant: "error" });
        setLoader(false);
      } else
        for (let i in response.data) {
          enqueueSnackbar(_.capitalize(i) + ": " + response.data[i][0], {
            variant: "error",
          });
          setLoader(false);
        }
    }

    if (!_.isEmpty(data)) {
      setPaymentDialogOpenSticitt(false);
      getPeachPaymentCredentials();
      getPaymentModes();
    }
    // showModeDetail(true)
  };
  const addSticittPaymentCredentials = async () => {
    if (!paymentFormDataSticitt.merchantId) {
      enqueueSnackbar("Please provide all mandatory details", {
        variant: "error",
      });
      return;
    }
    setLoader(true);
    let res = await axiosInstance.post(
      APIRouteConstants.STORE.SET_STICITT_CREDENTIAL,
      {
        sticitt_merchant_id: paymentFormDataSticitt.merchantId,
      }
    );
    if (res === false) {
      setLoader(false);
      return;
    }
    let { data, response } = res;

    if (response && response.status === 400 && response.data) {
      if (response.data.error) {
        enqueueSnackbar(response.data.error.message, { variant: "error" });
        setLoader(false);
      } else
        for (let i in response.data) {
          enqueueSnackbar(_.capitalize(i) + ": " + response.data[i][0], {
            variant: "error",
          });
          setLoader(false);
        }
    }

    if (!_.isEmpty(data)) {
      setPaymentDialogOpen(false);
      getSticittPaymentCredentials();
      getPaymentModes();
    }
    // showModeDetail(true)
  };

  const [modeDetail, showModeDetail] = useState(true);

  return (
    <div className="website-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Payments"} />
      </div>
      <Grid container className="component-grid">
        <Grid item xs={12}>
          <Card className="list-wrapper dashboard-card-wrapper">
            <CardContent>
              <div className="header-wrapper">
                <span className="header-text">Payment Methods</span>
              </div>
              <Divider />
              <div className="content-wrapper content-flex">
                <span className="content-text">
                  To start accepting payments on your online store, you need to
                  set up the payment method(s) you prefer to use for your
                  business. It is important to offer a variety of methods to pay
                  including Credit card, Mobile Money etc. The more ways you
                  enable your customers to pay, the higher chance you have of
                  making sales. Always make it easier for your customers to pay
                  in the way that suits them.
                </span>
              </div>
              <div className="content-wrapper content-flex">
                <Grid container className="filter-wrapper">
                  {allPaymentModes.length > 0
                    ? allPaymentModes.map((mode) => (
                        <Grid item sm={12} className="payment-card">
                          <div className="payment-header">
                            <div className="detail-sec-wrapper">
                              {_.includes(
                                _.toLower(
                                  _.replace(
                                    mode["payment_method_name"],
                                    " ",
                                    ""
                                  )
                                ),
                                "peach"
                              ) ? (
                                <div>
                                  <img
                                    className="logo-image"
                                    src={require("../../../../assets/images/peach.png")}
                                    alt=""
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {_.includes(
                                _.toLower(
                                  _.replace(
                                    mode["payment_method_name"],
                                    " ",
                                    ""
                                  )
                                ),
                                "sticitt"
                              ) ? (
                                <div>
                                  <img
                                    className="logo-image"
                                    src={
                                      require('assets/icons/Sticittmerchant.png')   }
                                    alt=""
                                  />
                                  {/* <img className="logo-image" src={require('../../../../assets/images/sticittpay.png')} alt="" /> */}
                                </div>
                              ) : (
                                ""
                              )}
                              <div>
                                <span className="detail-sec-text">
                                  {mode["payment_method_name"]}
                                  <br />
                                </span>
                                {mode["payment_method_name"]==="Sticitt Pay" &&   <span className="content-text">
                                   Sticitt Pay is a one-stop-shop to clear the clutter of receipts and cash at your school.
                                   </span>}
                                {/* {_.includes(_.toLower(_.replace(mode['payment_method_name'], ' ', '')), 'peach') ?
                                                       <span className='detail-sec-text'>
                                                       <a className="label-text" 
                                                        onClick={() => {
                                                            if (!modeDetail)
                                                                showModeDetail(true)
                                                            else {
                                                                showModeDetail(false)
                                                            }
                                                        }}>
                                                            
                                                        {modeDetail ? 'Read Less' : 'Read More'}
                                                        </a> 
                                                       </span> 
                                                         : ''} */}
                              </div>
                            </div>
                            <Switch
                              checked={mode["status"]}
                              color="success"
                              disabled={
                                (() => {
                                  const paymentMethodName = _.toLower(_.replace(mode["payment_method_name"], "", ""));
                                  
                                  console.log("peachCredential",paymentMethodName)
                                  if (paymentMethodName.includes("sticitt pay")) {
                                    // Disable if sticittCredential is undefined, null, or empty
                                    return !sticittCredential || _.isEmpty(sticittCredential);
                                  }
                                  
                                  if (paymentMethodName.includes("peach payments")) {
                                    // Disable if peachCredential is undefined, null, or empty
                                    return !peachCredential || _.isEmpty(peachCredential);
                                  }
                              
                                  return false; // Enable for other payment methods
                                })()
                              }
                              
                              // disabled={
                              //   _.includes(
                              //     _.toLower(
                              //       _.replace(
                              //         mode["payment_method_name"],
                              //         " ",
                              //         ""
                              //       )
                              //     ),
                              //     "Sticitt Pay"
                              //   )
                              //     ? !_.isEmpty(peachCredential)
                              //       ? false
                              //       : true
                              //     : _.includes(
                              //         _.toLower(
                              //           _.replace(
                              //             mode["payment_method_name"],
                              //             " ",
                              //             ""
                              //           )
                              //         ),
                              //         "Peach Payments"
                              //       )
                              //     ? !_.isEmpty(peachCredential)
                              //       ? false
                              //       : true
                              //     : false
                              // }
                              onChange={() => {
                                updatePaymentMode(mode["id"], !mode["status"]);
                              }}
                            />
                          </div>
                          {_.includes(
                            _.toLower(
                              _.replace(mode["payment_method_name"], " ", "")
                            ),
                            "sticitt"
                          ) ? (
                            <Collapse
                              in={modeDetail}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Divider className="divider" />
                              {!_.isEmpty(sticittCredential) ? (
                                <div className="payment-detail">
                                  <span>
                                    Merchant Id :{" "}
                                    {_.get(sticittCredential, "sticitt_merchant_id")}
                                  </span>
                                </div>
                              ) : (
                                <div className="payment-detail">
                                  <Button
                                    href="https://sticitt.co.za/"
                                    target="_blank"
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginRight: "1rem" }}
                                  >
                                    Create Account Now
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setPaymentDialogOpen(true);
                                    }}
                                    variant="contained"
                                    color="success"
                                  >
                                    Have an account? Link Now.
                                  </Button>
                                  {/* <br /><br />
                                                        <span><b>Instructions:</b></span><br />
                                                        <span>1. Go to{" "}
                                                            <a target="_blank" href="https://www.peachpayments.com/resources/get-started">
                                                                Peach Payment</a> {" "}to setup credentials</span><br /> */}
                                </div>
                              )}
                            </Collapse>
                          ) : _.includes(
                              _.toLower(
                                _.replace(mode["payment_method_name"], " ", "")
                              ),
                              "peach"
                            ) ? (
                            <Collapse
                              in={modeDetail}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Divider className="divider" />
                              {!_.isEmpty(peachCredential) ? (
                                <div className="payment-detail">
                                  <span>
                                    Entity Id :{" "}
                                    {_.get(peachCredential, "entity_id")}
                                  </span>
                                  <br />
                                  <span>
                                    Secret Token :{" "}
                                    {_.get(peachCredential, "username")}
                                  </span>
                                  <br />
                                  {/* <span>Password : {_.get(peachCredential, 'password')}</span><br /> */}
                                  <span>Password : xxxxxxx</span>
                                  <br />
                                  {/* <span>Access Key : {_.get(peachCredential, 'access_key')}</span><br /> */}
                                </div>
                              ) : (
                                <div className="payment-detail">
                                  <Button
                                    href="https://www.peachpayments.com/resources/get-started"
                                    target="_blank"
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginRight: "1rem" }}
                                  >
                                    Create Account Now
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setPaymentDialogOpenSticitt(true);
                                    }}
                                    variant="contained"
                                    color="success"
                                  >
                                    Have an account? Link Now.
                                  </Button>
                                  {/* <br /><br />
                                                        <span><b>Instructions:</b></span><br />
                                                        <span>1. Go to{" "}
                                                            <a target="_blank" href="https://www.peachpayments.com/resources/get-started">
                                                                Peach Payment</a> {" "}to setup credentials</span><br /> */}
                                </div>
                              )}
                            </Collapse>
                          ) : (
                            ""
                          )}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </div>
            </CardContent>
            {!user?.isPublished ? (
              <CardActions className="footer-wrapper">
                <div className="action-wrapper">
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ backgroundColor: "white" }}
                    onClick={() => {
                      navigate(dashboardRouteConstants.HOME);
                    }}
                  >
                    Next {">"}
                  </Button>
                </div>
              </CardActions>
            ) : (
              ""
            )}
          </Card>
        </Grid>

        {/* FOr PEACH PAY */}

        <FormDialog
          setOpen={() => setPaymentDialogOpenSticitt(!isPaymentDialogOpenSticitt)}
          viewSave={true}
          viewCancel={true}
          open={isPaymentDialogOpenSticitt}
          className="credential-dialog"
          title={"Set Peach Payments Checkout Credentials"}
          handleSave={() => addPeachPaymentCredentials()}
        >
          <div className="credential-wrapper">
            <Grid container>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Entity Id
                    <span className="required">*</span>
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Entity Id"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setFormData({
                        ...paymentFormData,
                        entityId: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Secret Token
                    {/* <span className='required'>*</span> */}
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="Username"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setFormData({
                        ...paymentFormData,
                        userName: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Password
                    {/* <span className='required'>*</span> */}
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder="xxxxxxxxxxxxxx"
                    type="password"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setFormData({
                        ...paymentFormData,
                        password: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </FormDialog>

        {/* FOr STICITT PAY */}

        <FormDialog
          setOpen={() => setPaymentDialogOpen(!isPaymentDialogOpen)}
          viewSave={true}
          viewCancel={true}
          open={isPaymentDialogOpen}
          className="credential-dialog"
          title={"Set Sticitt Payments Checkout Credentials"}
          handleSave={() => addSticittPaymentCredentials()}
        >
          <div className="credential-wrapper">
            <Grid container>
              <Grid xs={12} className="form-wrapper">
                <FormLabel className="form-label">
                  <span className="label-text">
                    Merchant Id
                    <span className="required">*</span>
                  </span>
                </FormLabel>
                <FormControl className="form-control">
                  <TextField
                    margin="dense"
                    id="name"
                    placeholder=" Merchant Id"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                        setFormDataSticitt({
                        ...paymentFormDataSticitt,
                        merchantId: e.target.value,
                      });
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </FormDialog>
      </Grid>
    </div>
  );
};

export default Payments;
