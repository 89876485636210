import { useState, useEffect } from "react";

import {
  Card,
  Divider,
  Grid,
  Button,
  CardContent,
  TablePagination,
  CardActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";

import AddCircle from "@mui/icons-material/AddCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ReactComponent as Add } from "assets/svgs/Add.svg";
import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import {
  APIRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import Loader from "components/Loader";

import NewCategory from "./NewCategory";
import BasicTable from "components/Tables/BasicTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import CloudUpload from "@mui/icons-material/CloudUpload";
import CloseIcon from '@mui/icons-material/Close';
import { BULK_UPLOAD_CATEGORIES_SAMPLE_URL } from "constants/appConstants";
const Categories = ({ breadcrumbs }) => {
  const [isNewCategory, setNewCategory] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const loggedInUser = useSelector((state) => state.userDetails);
  const { user } = loggedInUser;
  console.log("user", user)
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  // const handleFileChange = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };
  const getCategoryList = async () => {
    setLoader(true);
    let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_CATEGORIES, {
      params: { page: pageCount + 1 },
    });
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.results && data.results.length) {
      setAllCategories(data.results);
      setTotalCount(data.count);
      setPageCount(data.current_page - 1);
    }
    setLoader(false);
  };

  const refreshCategory = () => {
    setNewCategory(false);
    getCategoryList();
  };

  const handleChangePage = (e, page) => {
    setPageCount(page);
  };

  useEffect(() => {
    getCategoryList();
  }, [pageCount]);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      console.log("filename", selectedFile);
      setFileName(selectedFile?.name);
      setFile(selectedFile);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  const handleFileUpload = async () => {
    setLoader(true);
    if (!file) {
      console.error("No file selected for upload");
      enqueueSnackbar("No file selected for upload", { variant: "error" });
      setLoader(false);
      return;
    }

    console.log("filename12", file);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(
        APIRouteConstants.STORE.UPLOAD_BULK_CATEGORIES,
        formData
      );

      if (response.status) {
        enqueueSnackbar("Categories uploaded successfully", {
          variant: "success",
        });
        setOpen(false)
        refreshCategory()
      } else {
        setFileName("")
        setFile(null)
        setOpen(false)
        enqueueSnackbar(response.response?.data?.error, {
          variant: "error",
        });
      }

      setLoader(false);
    } catch (error) {
      enqueueSnackbar("something went wrong", { variant: "error" });

      setLoader(false);
    }
  };

  return (
    <div className="store-analytics-wrapper website-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Categories"} />
      </div>
      {isNewCategory ? (
        <NewCategory
          discardNewCategory={refreshCategory}
          allCategories={allCategories}
        />
      ) : (
        <Grid container className="component-grid">
          <Grid item xs={12}>
            <Card className="list-wrapper dashboard-card-wrapper">
              <CardContent>
                <div className="header-wrapper header-flex">
                  <span className="header-text">Categories {`>`} All</span>

                  <div className="header-wrapper_category">
                    {!isNewCategory && <Button
                      onClick={() => setNewCategory(true)}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      startIcon={<AddCircle />}
                    >
                      Add New Category
                    </Button>}
                  </div>


                </div>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                  pt={0.5}
                >
                  <p style={{ fontSize: "14px", padding: 5 }}>
                    You can now upload all your categories in one go. Accepted file
                    formats are CSV, XLS, XLSX, and JSON.
                  </p>
                  <Box
                    padding={0.5}
                    display="flex"
                    alignItems="center"
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      size="small"
                      variant="outlined"
                      style={{
                        color: "#1876d2",
                        paddingInline: 25,
                        borderColor: "#1876d2",
                      }}
                      onClick={handleClickOpen}
                    >
                      Upload
                      {/* Upload Bulk Category */}
                      <CloudUploadIcon
                        style={{ color: "#1876d2", marginLeft: 2 }}
                      />
                    </Button>
                  </Box>

                  <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'} pr={3}>
                      <DialogTitle>Upload Bulk Category</DialogTitle>
                      <div onClick={() => setOpen(false)}>
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </div></Box>
                    <DialogContent>
                      <div style={{ backgroundColor: "#6464641A", padding: 15, borderRadius: 10 }}>
                        <Typography variant="body1" gutterBottom sx={{ fontSize: '0.9rem' }}>
                          Steps to Follow Bulk Upload
                        </Typography>
                        <Box display={"flex"} justifyContent={"space-between"} pt={2} pb={2}>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Download the sample file and replace its data with your order data.
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Make sure all mandatory fields are filled.
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Save the file and upload it back.
                            </Typography>
                          </Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            component="a"
                            size='small'
                            href={BULK_UPLOAD_CATEGORIES_SAMPLE_URL}
                            download
                            style={{ marginBottom: '16px' }}
                          >
                            Download Sample File
                          </Button>
                        </Box>
                        <Box
                          border="1px dashed #1876D2"
                          borderRadius="4px"
                          padding="16px"
                          textAlign="center"
                          style={{ cursor: 'pointer' }}
                        >
                          <input
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            accept=".csv,.xlsx,.xls,.json"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                            <CloudUpload fontSize="large" color="action" sx={{ color: "#1876D2" }} />
                            {fileName && (
                              <Typography variant="body2" color="textPrimary" sx={{ fontSize: '0.8rem' }}>
                                {fileName}
                              </Typography>
                            )}
                            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.8rem' }}>
                              Select a file or drag and drop here (CSV, XLS, XLSX, and JSON)
                            </Typography>
                          </label>
                        </Box>

                        <Button
                          sx={{ textTransform: "none", background: "#1876D2" }}
                          variant="contained"
                          onClick={handleFileUpload}
                          disabled={loading}
                          style={{ marginTop: '16px' }}
                        >
                          {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                </Box>

                {allCategories.length ? (
                  <Grid
                    container
                    className="dashboard-table-container"
                    spacing={4}
                    wrap="wrap"
                  >
                    <Grid item md={12} sm={12}>
                      <BasicTable
                        rows={allCategories}
                        headers={[
                          {
                            label: "Category Name",
                            name: "name",
                          },
                          {
                            label: "Category Description",
                            name: "description",
                            width: "50%",
                          },
                          {
                            label: "Category Parent",
                            name: "parent_category_name",
                          },
                          ,
                          {
                            label: "Status",
                            name: "status",
                            type: "status",
                          },
                        ]}
                      />
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={totalCount < 10 ? totalCount : 10}
                        page={pageCount}
                        onPageChange={handleChangePage}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <div className="content-wrapper content-flex content-flex-center">
                    <Add />
                    <div className="detail-action-wrapper content-flex content-flex-center">
                      <span className="detail-text">
                        Start adding categories to your store
                      </span>
                      <br />
                      <p className="detail-sec-text">
                        Categories organise your content in a way that makes it
                        easier for your customers to find what they are looking
                        for.
                      </p>
                      <div className="button-wrapper">
                        <Button
                          onClick={() => setNewCategory(true)}
                          variant="contained"
                          color="secondary"
                          startIcon={<AddCircle />}
                        >
                          Add Category
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </CardContent>
              {!user?.isPublished ? (
                <CardActions className="footer-wrapper">
                  <div className="action-wrapper">
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ backgroundColor: "white" }}
                      onClick={() => {
                        navigate(dashboardRouteConstants.PRODUCTS);
                      }}
                    >
                      Next {">"}
                    </Button>
                  </div>
                </CardActions>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Categories;
