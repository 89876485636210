// import React from "react";
// import WithFooter from "./WithFooter";

// import "./formstyle.css";
// import "./globalstylesheet-sai.css";
// import "./globalstylesheet.css";
// function Pricingv1() {
//   return (
//     <WithFooter>
//       <div className="pricing">
//         <div className="pricing__container">
//           <div className="pricing__container__heading">Webbie Plans</div>
//           <div className="pricing__container__content">
//             Select the plan that works best for you. Change plans as you grow.
//             Simplified pricing, unlimited possibility. Whether you are on the
//             basic or premium plan, get access to priority support and assistance
//             on all your inquiries, concerns, or issues.
//           </div>
//           <div className="modal-container" id="modalContainer">
//             <div className="modal-content">
//               <div className="close-icon" onclick="closeModal()">
//                 &times;
//               </div>
//               <div className="image">
//                 <img src="images/subscription.svg" alt="Subscribe" />
//               </div>

//               <div className="modal-text">
//                 Webbie is currently free, we will come up with the pricing soon.
//               </div>
//             </div>
//           </div>
//           <div className="pricing__container__subscribe">
//             <div className="pricing__container__subscribe__card">
//               <div className="pricing__container__subscribe__card__image">
//                 <img src="images/airdrop.jpg" alt="airdropimage" />
//               </div>
//               <div className="pricing__container__subscribe__card__content">
//                 <div className="heading">14 DAY TRAIL</div>
//                 <div className="borderline"></div>
//                 <div className="text">
//                   Try it free for 14 days. Get full access to all the features
//                   and expert help.
//                 </div>
//                 <div className="buttondiv">
//                   <button>FREE PLAN</button>
//                 </div>
//               </div>
//             </div>
//             <div className="pricing__container__subscribe__card">
//               <div className="pricing__container__subscribe__card__image">
//                 <img src="images/rocketimg.svg" alt="airdropimage" />
//               </div>
//               <div className="pricing__container__subscribe__card__content">
//                 <div className="heading">BASIC ANNUAL PLAN</div>
//                 <div className="borderline"></div>
//                 <div className="text">
//                   For small teams. Grow and manage your online store with ease.
//                 </div>
//                 <div className="buttondiv">
//                   <button onclick="openModal()">R 499.00</button>
//                 </div>
//               </div>
//             </div>
//             <div className="pricing__container__subscribe__card">
//               <div className="pricing__container__subscribe__card__image">
//                 <img src="images/ufo.jpg" alt="airdropimage" />
//               </div>
//               <div className="pricing__container__subscribe__card__content">
//                 <div className="heading">PREMIUM ANNUAL PLAN</div>
//                 <div className="borderline"></div>
//                 <div className="text">
//                   Scale your business with advanced features like WhatsApp,
//                   Facebook, Instagram shopping catalog integration (Coming Soon)
//                 </div>
//                 <div className="buttondiv">
//                   <button onclick="openModal()">R 999.00</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </WithFooter>
//   );
// }

// export default Pricingv1;
import React from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import "./pricing.css";
import CustomBreadcrumbs from "components/Breadcrumbs";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
} from "@mui/material";
import { TableView } from "@mui/icons-material";

import { ReactComponent as Rocket } from "assets/svgs/rocket.svg";
import { ReactComponent as NoData } from "assets/svgs/NoData.svg";
import { GLOBAL_CURRENCY } from "constants/appConstants";
import parachute from "../../assets/images/parachut.jpg";
import spaceship from "../../assets/images/spaceship.jpg";
// import spaceship from "../../assets/images/r";
let formatCurrency = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: GLOBAL_CURRENCY.code,
  currencyDisplay: "narrowSymbol",
  minimumFractionDigits: 2,
});
function Pricingv1() {
  return (
    <WithFooter>
      <div className="overlay-wrapper">
        <Grid xs={12}>
          <Card className="list-wrapper dashboard-card-wrapper">
            <CardContent>
              <div>
                <span className="header-text" style={{ paddingLeft: 15 }}>
                  Webbie Plans
                </span>
              </div>
              <Divider />
              <div className="content-wrapper content-flex">
                <span className="content-text" style={{ paddingLeft: 15 }}>
                  Please choose the subscription plan that best suits your
                  needs. Note that you won't be able to publish your website or
                  process orders until you select a plan. All the options below
                  are monthly and provide excellent value for the great features
                  included.
                </span>
              </div>
              <div
                className="content-wrapper content-flex"
                style={{
                  padding: "0px !important",
                }}
              >
                <Grid
                  container
                  className="plan-wrapper"
                  style={{ paddingBottom: "0 !important" }}
                  spacing={4}
                >
                  <Grid md={4} sm={6} xs={12}>
                    <Card
                      style={{ paddingTop: "0", paddingBottom: "0 !important" }}
                      className="selected plan-card-wrapper"
                    >
                      <div className="detail-wrapper">
                        <img src={parachute} />
                        <span className="plan-text">15 Days trial</span>
                        <div className="separator" />
                        <Grid
                          sx={{ minHeight: { xs: "", sm: "30h", md: "30vh" } }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <span className="plan-sec-text">
                            A limited trial where you can test all the features
                            that Webbie has to offer.
                          </span>
                        </Grid>
                        <br />
                        <Button variant="contained">
                         Try For Free 
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                  <Grid md={4} sm={6} xs={12}>
                    <Card
                      style={{ paddingTop: "0", paddingBottom: "0 !important" }}
                      className="plan-card-wrapper"
                    >
                      <div className="detail-wrapper">
                        <Rocket />
                        <span className="plan-text">Basic Plan</span>
                        <div className="separator" />
                        {/* <span className="plan-sec-text">
                          {/* A limited trial where you can test all the features
                          that Webbie has to offer. */}
                        {/* </span> */}
                        <Grid
                          sx={{ minHeight: { xs: "", sm: "30vh", md: "30vh" } }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems:"flex-start",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          <ul
                            style={{
                              margin: 0,
                              paddingLeft: "1.2rem",
                              fontSize: "0.7rem",
                              fontWeight: "400",
                              whiteSpace: "nowrap",
                              fontFamily: "Mulish , sans-serif !important",
                            }}
                          >
                            <li
                              style={{
                                fontFamily: "Mulish , sans-serif !important",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  50 product limit
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  35 transaction limit
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  Create discount vouchers
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                      Shipping API or Choose Your Own Shipping
                                      </Grid>
                                    </Grid>
                                  </li>
                            <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                      You Get free domain with webbieshop extension
                                      </Grid>
                                    </Grid>
                                  </li>
                          </ul>
                        </Grid>
                        <br />
                        <Button variant="contained">
                          {/* {formatCurrency.format(299)} */}
                          R299/month
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                  <Grid md={4} sm={6} xs={12}>
                    <Card
                      style={{ paddingTop: "0", paddingBottom: "0" }}
                      className="plan-card-wrapper"
                    >
                      <div className="detail-wrapper">
                        <img src={spaceship} />
                        <span className="plan-text">Premium Plan </span>
                        <div className="separator" />
                        {/* <span className="plan-sec-text">
                          A limited trial where you can test all the features
                          that Webbie has to offer.
                        </span> */}
                        <Grid
                          sx={{ minHeight: { xs: "", sm: "30vh", md: "30vh" } }}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          {/* <p
                            style={{ fontSize: "0.7rem", whiteSpace: "nowrap" }}
                          >
                            Plan includes:
                          </p> */}
                          <ul
                            style={{
                              margin: 0,
                              paddingLeft: "1.2rem",
                              fontSize: "0.7rem",
                              fontWeight: "400",
                              fontFamily: "Mulish , sans-serif !important",
                              color: "#000000de",
                              // whiteSpace: "nowrap",
                            }}
                          >
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  100 product limit
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  150 transaction limit
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  Create discount vouchers
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  Shipping API or Choose Your Own Shipping
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  Includes accounts
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  Upload Bulk products and categories
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                  Change colors and themes based on your brand
                                  entity
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container>
                                <Grid item xs={12} fontFamily={"Mulish"}>
                                Sell under your own domain name
                                </Grid>
                              </Grid>
                            </li>
                          </ul>
                        </Grid>
                        <br />
                        <Button variant="contained" >
                          {/* Coming Soon */}
                          {/* {formatCurrency.format(599)} */}
                          R799/month
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
            {/* <CardActions className="footer-wrapper">
              <div className="action-wrapper"></div>
            </CardActions> */}
          </Card>
        </Grid>

        {/* <div className="subscription-card">
          <NoData className="statement-svg" />
          <span className="statement">
            Webbie is currently free, we will come up with the pricing soon.
          </span>
        </div> */}
      </div>
    </WithFooter>
  );
}

export default Pricingv1;

// <div className="pricing">
//         <div className="blurry-background">
//           <div className="blurry-content">
//             <div className="blurry-text" style={{ fontSize: 24 }}>
//               Webbie is currently free, we will come up with the pricing soon.
//             </div>
//           </div>
//         </div>
//         <div className="pricing__container">
//           <div className="pricing__container__heading">Webbie Plans</div>
//           <div className="pricing__container__content">
//             Select the plan that works best for you. Change plans as you grow.
//             Simplified pricing, unlimited possibility. Whether you are on the
//             basic or premium plan, get access to priority support and assistance
//             on all your inquiries, concerns, or issues.
//           </div>
//           <div className="modal-container" id="modalContainer">
//             <div className="modal-content">
//               <div className="close-icon" onclick="closeModal()">
//                 &times;
//               </div>
//               <div className="image">
//                 <img src="images/subscription.svg" alt="Subscribe" />
//               </div>

//               <div className="modal-text">
//                 Webbie is currently free, we will come up with the pricing soon.
//               </div>
//             </div>
//           </div>
//           <div className="pricing__container__subscribe">
//             <div className="pricing__container__subscribe__card">
//               <div className="pricing__container__subscribe__card__image">
//                 <img src="images/airdrop.jpg" alt="airdropimage" />
//               </div>
//               <div className="pricing__container__subscribe__card__content">
//                 <div className="heading">14 DAY TRIAL</div>
//                 <div className="borderline"></div>
//                 <div className="text">
//                   Try it free for 14 days. Get full access to all the features
//                   and expert help.
//                 </div>
//                 <div className="buttondiv">
//                   <button>FREE PLAN</button>
//                 </div>
//               </div>
//             </div>
//             <div className="pricing__container__subscribe__card">
//               <div className="pricing__container__subscribe__card__image">
//                 <img src="images/rocketimg.svg" alt="rocketimage" />
//               </div>
//               <div className="pricing__container__subscribe__card__content">
//                 <div className="heading">BASIC ANNUAL PLAN</div>
//                 <div className="borderline"></div>
//                 <div className="text">
//                   For small teams. Grow and manage your online store with ease.
//                 </div>
//                 <div className="buttondiv">
//                   <button>R 499.00</button>
//                 </div>
//               </div>
//             </div>
//             <div className="pricing__container__subscribe__card">
//               <div className="pricing__container__subscribe__card__image">
//                 <img src="images/ufo.jpg" alt="ufoimage" />
//               </div>
//               <div className="pricing__container__subscribe__card__content">
//                 <div className="heading">PREMIUM ANNUAL PLAN</div>
//                 <div className="borderline"></div>
//                 <div className="text">
//                   Scale your business with advanced features like WhatsApp,
//                   Facebook
//                 </div>
//                 <div className="buttondiv">
//                   <button>R 999.00</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
