import { useState, useEffect } from 'react';
import _ from 'lodash';

import {
    Card,
    CardContent,
    Divider,
    Grid,
    FormLabel,
    FormControl,
    TextField,
    Button,
    CardActions
} from '@mui/material';
import { useSnackbar } from 'notistack';

import CustomBreadcrumbs from "components/Breadcrumbs";
import axiosInstance from "configs/axiosConfig";
import { APIRouteConstants, dashboardRouteConstants } from 'constants/routeConstants';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';

const About = ({ breadcrumbs }) => {
    const [aboutUs, setAboutUs] = useState('');
    const [storeName, setStoreName] = useState('');
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const getAboutUs = async () => {
        setLoader(true)
        let res = await axiosInstance.get(APIRouteConstants.WEBSITE.WEB_ABOUT_US)
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;

        if (response
            && response.status === 404) {
            setAboutUs()
            setStoreName('')
        }

        if (data) {
            setAboutUs(data['about'])
            setStoreName(data['store_name'])
        }
        setLoader(false)
    }

    useEffect(() => {
        getAboutUs()
    }, [])

    const updateAboutUs = async () => {
        if (!aboutUs && !storeName) return;
        setLoader(true)
        const res = await axiosInstance({
            method: 'post',
            url: APIRouteConstants.WEBSITE.WEB_ABOUT_US,
            data: {
                store_name: storeName,
                about: aboutUs
            }
        })
        if (res === false) {
            setLoader(false);
            return
        }
        let { data, response } = res;
        if (response
            && response.status === 400
            && response.data) {
            for (let i in response.data) {
                enqueueSnackbar(_.capitalize(i) + ': ' + response.data[i], { variant: 'error' });
            }
        }
        if (!_.isEmpty(data)) {
            enqueueSnackbar('Details updated successfully', { variant: 'success' });
        }
        setLoader(false)
    }

    const handleUpdate = () => {
        updateAboutUs()
    }

    return (
        <div className="website-analytics-wrapper store-analytics-wrapper">
            <Loader open={loader} />
            <div className='breadcrumb-wrapper'>
                <CustomBreadcrumbs list={breadcrumbs} name={"About"} />
            </div>
            <Grid container className='component-grid'>
                <Grid item xs={12}>
                    <Card className='dashboard-card-wrapper'>
                        <CardContent className='content-bottom-wrapper'>
                            <div className='header-wrapper'>
                                <span className='header-text'>About Page</span>
                            </div>
                            <Divider />
                            <div className='content-wrapper'>
                                <span className='content-text'>
                                    Tell us about your business.
                                </span>
                            </div>
                            <Grid container className='form-grid-container dashboard-form-container' spacing={4} wrap='wrap'>
                                {/* <Add /> */}
                                <Grid item md={12} sm={12}>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                Store name
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Please enter your store name.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                placeholder="Enter Store Name"
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={storeName}
                                                onChange={e => setStoreName(e.target.value)}
                                                InputLabelProps={{ shrink: false }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='form-wrapper'>
                                        <FormLabel className='form-label'>
                                            <span className='label-text'>
                                                About us page details
                                            </span><br />
                                            <span className='label-sec-text'>
                                                Tell us about your business.
                                            </span>
                                        </FormLabel>
                                        <FormControl className='form-control'>
                                            <ReactQuill
                                                className='editor'
                                                value={aboutUs}
                                                onChange={e => setAboutUs(e)}
                                                theme="snow" />
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className='footer-wrapper'>
                            <div className="action-wrapper">
                                <Button variant="contained" color="secondary" onClick={handleUpdate}>
                                    Update
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                        navigate(dashboardRouteConstants.HOME)
                                    }}>
                                    Next {'>'}
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default About