import { useState, useEffect } from "react";

import {
  Card,
  Divider,
  Grid,
  Button,
  CardContent,
  TablePagination,
  CardActions,
  IconButton,
  TextField,
} from "@mui/material";
import _ from "lodash";

import { Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';
import AddCircle from "@mui/icons-material/AddCircleOutline";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ReactComponent as Add } from "assets/svgs/Add.svg";
import CustomBreadcrumbs from "components/Breadcrumbs";
import NewProduct from "./NewProduct";
import axiosInstance from "configs/axiosConfig";
import {
  APIRouteConstants,
  dashboardRouteConstants,
} from "constants/routeConstants";
import Loader from "components/Loader";
import TableWithAction from "components/Tables/TableWithAction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import CloudUpload from "@mui/icons-material/CloudUpload";
import CloseIcon from '@mui/icons-material/Close';
import { BULK_UPLOAD_PRODUCTS_SAMPLE_URL } from "constants/appConstants";
import getUser from "reducers/userDetails";
import { updateUserDetail } from "utils/auth";
const Products = ({ breadcrumbs }) => {
  const [isNewProduct, setNewProduct] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [isEditProduct, setEditProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [loader, setLoader] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [productId, setSelectedProductId] = useState();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const loggedInUser = useSelector((state) => state.userDetails);
  const location = useLocation();
  const { user } = loggedInUser;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  // const handleFileChange = (event) => {
  //     setSelectedFile(event.target.files[0]);
  // };
  useEffect(() => {
    if (location && location.state) {
      const { productId } = location.state;
      setSelectedProductId(productId);
    }
  }, [location]);

  const getProductList = async () => {
    setLoader(true);
    let res = await axiosInstance.get(APIRouteConstants.STORE.ALL_PRODUCTS, {
      params: { page: pageCount + 1 },
    });
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.results && data.results.length) {
      setAllProducts(data.results);
      setTotalCount(data.count);
      setPageCount(data.current_page - 1);
      setLoader(false);
    }
    setLoader(false);
  };

  // useEffect(() => {
  //     getProductList()
  // }, [])

  const refreshProduct = () => {
    setNewProduct(false);
    setSelectedProduct("");
    setEditProduct(false);
    getProductList();
  };

  const handleEdit = (product) => {
    setSelectedProduct(product.id);
    setEditProduct(true);
  };

  const handleChangePage = (e, page) => {
    setPageCount(page);
  };
  const getUser = async () => {
    let res = await axiosInstance.get(APIRouteConstants.AUTH.ME)
    if (res === false) {
      setLoader(false);
      return
    }
    let { data, response } = res;

    if (data && data.success) {
      const user = data.data;
      updateUserDetail(user)
    }
    setLoader(false);
  }
  useEffect(() => {
    getProductList();
    getUser();
  }, [pageCount]);

  useEffect(() => {
    if (productId) {
      setSelectedProduct(productId);
      setEditProduct(true);
    }
  }, [productId]);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      console.log("filename", selectedFile);
      setFileName(selectedFile?.name);
      setFile(selectedFile);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  const handleFileUpload = async () => {
    setLoader(true);
    if (!file) {
      console.error("No file selected for upload");
      enqueueSnackbar("No file selected for upload", { variant: "error" });
      setLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(
        APIRouteConstants.STORE.UPLOAD_BULK_PRODUCTS,
        formData
      );
      if (response === false) {
        setLoader(false);
        return
      }

      if (response.status) {
        enqueueSnackbar("Products uploaded successfully", {
          variant: "success",
        });
        setOpen(false)
        getProductList()
      } else {
        setFileName("")
        setFile(null)
        setOpen(false)
        enqueueSnackbar(response.response?.data?.error, { variant: "error" });
      }
      setLoader(false);
    } catch (error) {
      enqueueSnackbar("something went wrong", { variant: "error" });
      setLoader(false);
    }
  };

  return (
    <div className="store-analytics-wrapper website-analytics-wrapper">
      <Loader open={loader} />
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Products"} />
      </div>
      {isNewProduct ? (
        <NewProduct
          discardNewProduct={refreshProduct}
          allCategories={allCategories}
        />
      ) : (
        ""
      )}
      {isEditProduct ? (
        <NewProduct
          discardNewProduct={refreshProduct}
          productId={selectedProduct}
          allCategories={allCategories}
        />
      ) : (
        ""
      )}
      {!isNewProduct && !isEditProduct ? (
        <Grid container className="component-grid">
          <Grid item xs={12}>
            <Card className="list-wrapper dashboard-card-wrapper">
              <CardContent>
                <div className="header-wrapper header-flex">
                  <span className="header-text">Products {`>`} All</span>
                  <div className="header-wrapper_category"></div>
                  <Button
                    onClick={() => setNewProduct(true)}
                    variant="outlined"
                    color="success"
                    size="small"
                    disabled={
                      _.isEmpty(user)
                        ? true
                        : !(user.is_category && user.warehouse_exist)
                    }
                    startIcon={<AddCircle />}
                  >
                    Add New Product
                  </Button>
                </div>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                  pt={0.5}
                >
                  {/* <div> */}
                  {/* <Button variant="contained" color="primary" onClick={handleClickOpen} size='small'>
                      Upload Bulk Products
                    </Button> */}
                  <p style={{ fontSize: "14px", padding: 5 }}>
                    You can now upload all your products in one go. Accepted file
                    formats are CSV, XLS, XLSX, and JSON.
                  </p>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"flex-end"}
                    pt={0.5}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      size="small"
                      variant="outlined"
                      style={{
                        color: "#1876d2",
                        paddingInline: 25,
                        borderColor: "#1876d2",
                      }}
                      onClick={handleClickOpen}
                    >
                      UPLOAD
                      {/* Upload Bulk Products */}
                      <CloudUploadIcon
                        style={{ color: "#1876d2", marginLeft: 5 }}
                      />
                    </Button>
                  </Box>
                  <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'} pr={3}>
                      <DialogTitle>Upload Bulk Products</DialogTitle>
                      <div onClick={() => setOpen(false)}>
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </div>
                    </Box>
                    <DialogContent>
                      <div style={{ backgroundColor: "#6464641A", padding: 15, borderRadius: 10 }}>
                        <Typography variant="body1" gutterBottom sx={{ fontSize: '0.9rem' }}>
                          Steps to Follow Bulk Upload
                        </Typography>
                        <Box display={"flex"} justifyContent={"space-between"} pt={2} pb={2}>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Download the sample file and replace its data with your order data.
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Make sure all mandatory fields are filled.
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                              Save the file and upload it back.
                            </Typography>
                          </Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            component="a"
                            size='small'
                            href={BULK_UPLOAD_PRODUCTS_SAMPLE_URL}
                            download
                            style={{ marginBottom: '16px' }}
                          >
                            Download Sample File
                          </Button>
                        </Box>
                        <Box
                          border="1px dashed #1876D2"
                          borderRadius="4px"
                          padding="16px"
                          textAlign="center"
                          style={{ cursor: 'pointer' }}
                        >
                          <input
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            accept=".csv,.xlsx,.xls,.json"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                            <CloudUpload fontSize="large" color="action" sx={{ color: "#1876D2" }} />
                            {fileName && (
                              <Typography variant="body2" color="textPrimary" sx={{ fontSize: '0.8rem' }}>
                                {fileName}
                              </Typography>
                            )}
                            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.8rem' }}>
                              Select a file or drag and drop here (CSV, XLS, XLSX, and JSON)
                            </Typography>
                          </label>
                        </Box>

                        <Button
                          variant="contained"
                          onClick={handleFileUpload}
                          disabled={loading}
                          style={{ marginTop: '16px' }}
                          sx={{ textTransform: "none", background: "#1876D2" }}
                        >
                          {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                  {/* </div> */}

                </Box>

                {loader && allProducts.length === 0 ? (
                  <div className="content-wrapper content-flex content-flex-center">
                    <Add />
                    <div className="detail-action-wrapper content-flex content-flex-center">
                      <span className="detail-text">
                        Start adding products to your store
                      </span>
                      <br />
                      <p className="detail-sec-text">
                        The product section is where you will find and manage
                        all the products in your store.
                        <br />
                        Please make sure you have added category and warehouse
                        delivery address before adding product.
                      </p>
                      <div className="button-wrapper">
                        <Button
                          onClick={() => setNewProduct(true)}
                          disabled={
                            _.isEmpty(user)
                              ? true
                              : !(user.is_category && user.warehouse_exist)
                          }
                          variant="contained"
                          color="secondary"
                          startIcon={<AddCircle />}
                        >
                          Add Product
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Grid
                    container
                    className="dashboard-table-container"
                    spacing={4}
                    wrap="wrap"
                  >
                    <Grid item md={12} sm={12}>
                      <TableWithAction
                        rows={allProducts}
                        headers={[
                          {
                            label: "Product Name",
                            name: "name",
                            width: "50%",
                          },
                          {
                            label: "Category",
                            name: "category_name",
                            width: "10%",
                          },
                          {
                            label: "Price",
                            name: "price",
                            width: "10%",
                          },
                          {
                            label: "Stock Status",
                            name: "review",
                            type: "custom",
                            width: "10%",
                            arguments: ["count"],
                            component: (count) => {
                              return (
                                <div className="cell-label">
                                  <CheckCircleIcon />{" "}
                                  <span>
                                    {count > 0 ? "Available" : "Out of Stock"}
                                  </span>
                                </div>
                              );
                            },
                          },
                        ]}
                        handleEdit={handleEdit}
                      />
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={totalCount < 10 ? totalCount : 10}
                        page={pageCount}
                        onPageChange={handleChangePage}
                      />
                    </Grid>
                  </Grid>
                )}
              </CardContent>
              {!user?.isPublished ? (
                <CardActions className="footer-wrapper">
                  <div className="action-wrapper">
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ backgroundColor: "white" }}
                      onClick={() => {
                        navigate(dashboardRouteConstants.HOME);
                      }}
                    >
                      Next {">"}
                    </Button>
                  </div>
                </CardActions>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};

export default Products;
