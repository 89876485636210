import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TutorialModal from "./TutorialModal";
import { useNavigate } from "react-router-dom";

const Tutorial = () => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
        navigate("/dashboard")
        console.log("Modal closed");
    };

    return (
   <TutorialModal open={open} handleClose={handleClose}/>
    );
};

export default Tutorial;
