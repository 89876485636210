import { useState } from "react";

import {
  Card,
  Divider,
  Grid,
  Button,
  CardContent,
  CardActions,
  Switch,
  Box,
  AppBar,
  Tabs,
  Tab,
  Typography,
  FormLabel,
  FormControl,
  TextField,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import {parachute} from "../"
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as NoData } from "assets/svgs/NoData.svg";

import { ReactComponent as Rocket } from "assets/svgs/rocket.svg";
import CustomBreadcrumbs from "components/Breadcrumbs";
import { GLOBAL_CURRENCY } from "constants/appConstants";

let formatCurrency = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: GLOBAL_CURRENCY.code,
  currencyDisplay: "narrowSymbol",
  minimumFractionDigits: 2,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TabView = ({ value, handleChange }) => {
  const theme = useTheme();

  return (
    <AppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{ backgroundColor: "#fff", color: "#000" }}
      >
        <Tab label="Plans" {...a11yProps(0)} />
        <Tab label="Billing Invoice" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
  );
};

const rows = [
  {
    date: "04/09/2023",
    invoice: "WEBBIE-1234",
    inv_detail: "Webbie Subscription - 15 Day Trial (03/09/23 - 17/09/23)",
    amount: "$0.00",
    status: "Paid",
    action: "Download",
  },
];

function BasicTable({ handleEdit }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <span>Date</span>
            </TableCell>
            <TableCell>
              <span>Invoice #</span>
            </TableCell>
            <TableCell width="40%">
              <span>Invoice Detail</span>
            </TableCell>
            <TableCell>
              <span>Amount</span>
            </TableCell>
            <TableCell>
              <span>Payment Status</span>
            </TableCell>
            <TableCell align="center">
              <span>Actions</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <span>{row.date}</span>
              </TableCell>
              <TableCell>
                <span>{row.invoice}</span>
              </TableCell>
              <TableCell>
                <span>{row.inv_detail}</span>
              </TableCell>
              <TableCell>
                <span>{row.amount}</span>
              </TableCell>
              <TableCell align="center">
                <div className="cell-label">
                  <CheckCircleIcon /> <span>{row.status}</span>
                </div>
              </TableCell>
              <TableCell align="center">
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={handleEdit}
                >
                  {row.action}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Billing = ({ breadcrumbs }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setValue(0);
  };

  const handleChangeIndex = (index) => {
    // setValue(index);
    setValue(0);
  };

  return (
    <div className="website-analytics-wrapper">
      <div className="breadcrumb-wrapper">
        <CustomBreadcrumbs list={breadcrumbs} name={"Billing details"} />
      </div>
      <div className="subscription-overlay overlay-wrapper">
        <div>
          <Box sx={{ bgcolor: "background.paper" }}>
            <TabView value={value} handleChange={handleChange} />
          </Box>
          <TabPanel value={value} index={0}>
            <Grid container className="component-grid">
              <Grid item xs={12}>
                <Card className="list-wrapper dashboard-card-wrapper">
                  <CardContent>
                    <div className="header-wrapper">
                      <span className="header-text">Webbie Plans</span>
                    </div>
                    <Divider />
                    <div className="content-wrapper content-flex">
                      <span className="content-text">
                        Please choose the subscription plan that best suits your
                        needs. Note that you won't be able to publish your
                        website or process orders until you select a plan. All
                        the options below are monthly and provide excellent
                        value for the great features included.
                      </span>
                    </div>
                    <div className="content-wrapper content-flex">
                      <Grid container className="plan-wrapper" spacing={4}>
                        <Grid item md={4} sm={6} xs={12}>
                          <Card className="plan-card-wrapper">
                            {/* <div className="detail-wrapper">
                              <Rocket />
                              <span className="plan-text">
                                Basic Annual Plan
                              </span>
                              <div className="separator" />
                              <span className="plan-sec-text">
                                A limited trial where you can test all the
                                features that Webbie has to offer.
                              </span>
                              <br />
                              <Button variant="contained">
                                {formatCurrency.format(399)}
                              </Button>
                            </div> */}
                            <div className="detail-wrapper">
                              <img
                                src={require("../../../../assets/images/parachut.jpg")}
                              />
                              <span className="plan-text">14 Day trial</span>
                              <div className="separator" />
                              {/* <span className="plan-sec-text">
                          {/* A limited trial where you can test all the features
                          that Webbie has to offer. */}
                              {/* </span> */}
                              <Grid
                                sx={{
                                  minHeight: { xs: "", sm: "32vh", md: "32vh" },
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  width: "100%",

                                  textAlign: "left",
                                }}
                              >
                                {/* <ul
                                  style={{
                                    margin: 0,
                                    // paddingLeft: "1.2rem",
                                    fontSize: "0.7rem",
                                    fontWeight: "400",
                                    // whiteSpace: "nowrap",
                                    fontFamily:
                                      "Mulish , sans-serif !important",
                                  }}
                                > */}

                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    fontFamily={"Mulish"}
                                    textAlign={"center"}
                                  >
                                    <Typography className="plan-sec-text">
                                      A limited trial where you can test all the
                                      features that Webbie has to offer.
                                    </Typography>
                                  </Grid>
                                </Grid>

                                {/* </ul> */}
                              </Grid>
                              <br />
                              <Button variant="contained">
                                {/* {formatCurrency.format(299)} */}
                                Try for free
                              </Button>
                            </div>
                          </Card>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <Card className="plan-card-wrapper">
                            {/* <div className="detail-wrapper">
                              <Rocket />
                              <span className="plan-text">
                                Basic Annual Plan
                              </span>
                              <div className="separator" />
                              <span className="plan-sec-text">
                                A limited trial where you can test all the
                                features that Webbie has to offer.
                              </span>
                              <br />
                              <Button variant="contained">
                                {formatCurrency.format(399)}
                              </Button>
                            </div> */}
                            <div className="detail-wrapper">
                              <Rocket />
                              <span className="plan-text">Basic Plan</span>
                              <div className="separator" />
                              {/* <span className="plan-sec-text">
                          {/* A limited trial where you can test all the features
                          that Webbie has to offer. */}
                              {/* </span> */}
                              <Grid
                                sx={{
                                  minHeight: { xs: "", sm: "32vh", md: "32vh" },
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  width: "100%",

                                  textAlign: "left",
                                }}
                              >
                                <ul
                                  style={{
                                    margin: 0,
                                    paddingLeft: "1.2rem",
                                    fontSize: "0.7rem",
                                    fontWeight: "400",
                                    whiteSpace: "nowrap",
                                    fontFamily:
                                      "Mulish , sans-serif !important",
                                  }}
                                >
                                  <li
                                    style={{
                                      fontFamily:
                                        "Mulish , sans-serif !important",
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        50 product limit
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        35 transaction limit
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        Create discount vouchers
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        fontFamily={"Mulish"}
                                        whiteSpace={"break-spaces"}
                                      >
                                        Shipping API or Choose Your Own Shipping
                                      </Grid>
                                    </Grid>
                                  </li>
                                </ul>
                              </Grid>
                              <br />
                              <Button variant="contained">
                                {/* {formatCurrency.format(299)} */}
                                R299/month
                              </Button>
                            </div>
                          </Card>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <Card className="plan-card-wrapper">
                            {/* <div className="detail-wrapper">
                              <img
                                style={{ width: "10rem" }}
                                src={require("../../../../assets/images/spaceship.jpg")}
                              />
                              <span className="plan-text">
                                Premium Annual Plan
                              </span>
                              <div className="separator" />
                              <span className="plan-sec-text">
                                A limited trial where you can test all the
                                features that Webbie has to offer.
                              </span>
                              <br />
                              <Button variant="contained">
                                {formatCurrency.format(599)}
                              </Button>
                            </div> */}
                            <div className="detail-wrapper">
                              <img
                                style={{ width: "10rem" }}
                                src={require("../../../../assets/images/spaceship.jpg")}
                              />
                              <span className="plan-text">Premium Plan </span>
                              <div className="separator" />
                              {/* <span className="plan-sec-text">
                          A limited trial where you can test all the features
                          that Webbie has to offer.
                        </span> */}
                              <Grid
                                sx={{
                                  minHeight: { xs: "", sm: "32vh", md: "32vh" },
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                {/* <p
                            style={{ fontSize: "0.7rem", whiteSpace: "nowrap" }}
                          >
                            Plan includes:
                          </p> */}
                                <ul
                                  style={{
                                    margin: 0,
                                    paddingLeft: "1.2rem",
                                    fontSize: "0.7rem",
                                    fontWeight: "400",
                                    fontFamily:
                                      "Mulish , sans-serif !important",
                                    color: "#000000de",
                                    // whiteSpace: "nowrap",
                                  }}
                                >
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        100 product limit
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        150 transaction limit
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        Create discount vouchers
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        Shipping API or Choose Your Own Shipping
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        Includes accounts.
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        Upload Bulk products and categories.
                                      </Grid>
                                    </Grid>
                                  </li>
                                  <li>
                                    <Grid container>
                                      <Grid item xs={12} fontFamily={"Mulish"}>
                                        Change colors and themes based on your
                                        brand entity.
                                      </Grid>
                                    </Grid>
                                  </li>
                                </ul>
                              </Grid>
                              <br />
                              <Button variant="contained">
                                {/* Coming Soon */}
                                {/* {formatCurrency.format(599)} */}
                                R799/month
                              </Button>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                  <CardActions className="footer-wrapper">
                    <div className="action-wrapper"></div>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid
              container
              className="dashboard-table-container"
              spacing={4}
              wrap="wrap"
            >
              <Grid item md={12} sm={12}>
                <BasicTable handleEdit={() => {}} />
              </Grid>
            </Grid>
          </TabPanel>
        </div>
        {/* <div className="subscription-card">
          <NoData className="statement-svg" />
          <span className="statement">
            Webbie is currently free, we will come up with the pricing soon.
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Billing;
{
  /* <Grid item md={4} sm={6} xs={12}>
                          <Card className="plan-card-wrapper">
                          {/* <Card className="selected plan-card-wrapper"> */
}
{
  /* <div className="detail-wrapper">
                              <img
                                src={require("../../../../assets/images/parachut.jpg")}
                              />
                              <span className="plan-text">14 Day trial</span>
                              <div className="separator" />
                              <span className="plan-sec-text">
                                A limited trial where you can test all the
                                features that Webbie has to offer.
                              </span>
                              <br />
                              <Button variant="contained" disabled>
                                Free Plan
                              </Button>
                            </div> */
}
//     <div className="detail-wrapper">
//       <img
//         src={require("../../../../assets/images/parachut.jpg")}
//       />
//       <span className="plan-text">15 Days trial</span>
//       <div className="separator" />
//       <Grid
//         sx={{
//           minHeight: { xs: "", sm: "32h", md: "32vh" },
//         }}
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "flex-start",
//           width: "100%",
//           textAlign: "center",
//         }}
//       >
//         <span className="plan-sec-text">
//           A limited trial where you can test all the
//           features that Webbie has to offer.
//         </span>
//       </Grid>
//       <br />
//       <Button variant="contained">Try For Free</Button>
//     </div>
//   </Card>
// </Grid> */}
